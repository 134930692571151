<template>
  <!-- 课程配置 -->
  <div>
    <!-- 返回 -->
    <el-button style="margin-bottom: 18px" @click="$router.back()"
      >返回</el-button
    >
    <!-- 条件 -->
    <div
      style="
        display: flex;
        justify-center: end;
        background: #fff;
        padding: 10px 0 10px 10px;
        justify-content: end;
      "
    >
      <el-form
        :inline="true"
        ref="conditionForm"
        :model="conditionForm"
        style="margin-bottom: -22px"
      >
        <el-form-item>
          <el-select
            v-model="conditionForm.sysOrgSchoolId"
            @change="inquireBtn"
          >
            <el-option label="全部学校" value=""></el-option>
            <el-option
              v-for="item in $store.state.public_data.sysSchoolDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="conditionForm.sysSubjectDicId"
            @change="inquireBtn"
          >
            <el-option label="全部学科" value=""></el-option>
            <el-option
              v-for="item in $store.state.public_data.sysSubjectDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetBtn">重置</el-button>
          <el-button type="primary" @click="inquireBtn">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-row>
      <el-table
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column type="index" width="70" label="序号"></el-table-column>
        <el-table-column
          prop="sysOrgSchoolName"
          width="200"
          label="学校"
        ></el-table-column>
        <el-table-column prop="sysSubjectDic" width="120" label="科目">
          <template slot-scope="scope">
            {{ scope.row.sysSubjectDic?.sysDicName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="crsExpCourseName"
          label="课程名称"
        ></el-table-column>
        <el-table-column
          prop="teacherName"
          width="120"
          label="上课老师"
        ></el-table-column>
        <el-table-column prop="crsExpCourseName" width="120" label="上课日期">
          <template slot-scope="scope">
            {{ scope.row.schoolTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column
          prop="crsOpenVoucherMark"
          label="课程开展情况"
        ></el-table-column>
        <el-table-column label="开课凭证附件">
          <template slot-scope="scope">
            <span class="imgList">
              <img
                v-for="e in scope.row.crsOpenVoucherList"
                :key="e"
                :src="this.$imageAddress(e)"
              />
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { getOpenPlanVoucherPageApi } from '@/api/courseStart/startClassStatistics.js'
export default {
  name: 'courseVoucher',
  data() {
    return {
      rangeType: null,
      sortFieldType: null,
      loading: false,
      conditionForm: {},
      tableData: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
    }
  },
  computed: {},
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
    this.$store.dispatch('getAllSchoolList')
  },
  mounted() {
    document.querySelector('body').setAttribute('style', 'background:#F5F6FA')
    this.rangeType = Number(this.$route.query.rangeType)
    this.sortFieldType = Number(this.$route.query.sortFieldType)
    this.conditionForm = {
      sysOrgSchoolId: '',
      sysSubjectDicId: '',
    }
    this.getOpenPlanVoucherPageApiFunc({})
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods: {
    // 课程开展凭证
    async getOpenPlanVoucherPageApiFunc(param) {
      await getOpenPlanVoucherPageApi(
        this.page.pageIndex,
        this.page.pageSize,
        param,
        this.rangeType
      )
        .then((res) => {
          if (res.success) {
            this.tableData = res.data
            this.page.totalSize = res.total
          } else {
          }
        })
        .catch((err) => {})
    },
    async changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getOpenPlanVoucherPageApiFunc(conditionObj)
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    resetBtn() {
      this.conditionForm.sysOrgSchoolId = ''
      this.conditionForm.sysSubjectDicId = ''
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    inquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 查看详情
    detailBtn(row) {},
  },
  filters: {
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.imgList {
  width: 50px;
  height: 50px;
  font-size: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
