import { request, noTimeOutReq } from '@/utils/request.js'

// 查询课程开展趋势
export function getOpenPlanTendencyApi () {
  return request({
    url: `/crs/open/plan/statistics/get/carryOut/tendency`,
    method: 'post',
  })
}
// 查询各校课程开展情况
export function getOpenSchoolStatisticsApi (rangeType) {
  return request({
    url: `/crs/open/plan/statistics/get/school/carryOut?rangeType=${rangeType}`,
    method: 'post'
  })
}
// 查询开课率统计概览
export function getOpenPlanOverviewApi () {
  return request({
    url: `/crs/open/plan/statistics/overview`,
    method: 'post',
  })
}
// 课程开展排行榜
export function getOpenPlanStatisticsRankingsPageApi (pageIndex, pageSize, data,rangeType) {
  return request({
    url: `/crs/open/plan/statistics/page/lesson/rankings/${pageIndex}/${pageSize}?rangeType=${rangeType}`,
    method: 'post',
    data
  })
}
// 分页查询课程开展情况
export function getOpenPlanStatisticsPageApi (pageIndex, pageSize, data,rangeType) {
  return request({
    url: `/crs/open/plan/statistics/page/lesson/${pageIndex}/${pageSize}?rangeType=${rangeType}`,
    method: 'post',
    data
  })
}
// 分页课程课时开课凭证
export function getOpenPlanVoucherPageApi (pageIndex, pageSize, data,rangeType) {
  return request({
    url: `/crs/open/plan/statistics/page/voucher/${pageIndex}/${pageSize}?rangeType=${rangeType}`,
    method: 'post',
    data
  })
}